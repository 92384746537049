import React from "react";
import About from "../components/home/About";
import Benefits from "../components/home/Benefits";
import HomeHero from "../components/home/HomeHero";
import MushroomTypes from "../components/home/MushroomTypes";
import WhyUs from "../components/home/WhyUs";

export default function Home() {
  return (
    <>
      <HomeHero />
      <About />
      <MushroomTypes />
      <Benefits />
      <WhyUs />
    </>
  );
}
