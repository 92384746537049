import React from "react";

const ProductCard = (props) => {
  return (
    <div className="product-card box-shadow">
      <img src={props.img} alt={props.pname} />
      <p className="pname bold">{props.pname}</p>
      <p className="pdesc">{props.pdesc}</p>
      <a
        href={props.link}
        target="_blank"
        rel="noopener noreferrer"
        className="pcta button boldTitle"
      >
        Order Now
      </a>
    </div>
  );
};

export default ProductCard;
