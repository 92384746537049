import React from "react";
import { Slide } from "react-reveal";
import micon from "../../assets/images/mrooms/mushroom-logo-icon.svg";

const AboutBody = () => {
  return (
    <section className="about">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="width-600 margin-auto section-spacing-80 no-padding-top">
                <Slide bottom>
                  <div className="about-head section-spacing-20">
                    <img src={micon} alt="Mushroom World Logo" />
                    <h3 className="center dark">MUSHROOM WORLD UGANDA</h3>
                  </div>
                  <div className="text-side-text justify">
                    <p>
                      Mushroom World Uganda Limited was established in the first
                      Covid lockdown as a means of utilizing the limited space
                      to generate household income with an inspiration from the
                      2019 Money Harvest Expo because mushrooms offered an
                      economic and high return on investment ratio compared to
                      other alternative farming ventures.{" "}
                    </p>
                    <p className="margin-top-10">
                      {" "}
                      We grow mushrooms of all types and deliver them packaged,
                      fresh and dried. We are located in Canan Estate, Katabi,
                      Entebbe and we do country wide delivery with free delivery
                      around Entebbe.
                    </p>
                    <p className="margin-top-10">
                      Today, Mushroom World Uganda Limited offers Oyster and
                      button mushrooms to our ever expanding market in Entebbe
                      and Kampala. Other than fresh oyster and button mushrooms,
                      we make spawns, mushroom gardens and substrates. All of
                      which we sell as well.
                    </p>
                    <p className="margin-top-10">
                      Our mushrooms are packaged in quantities of 200g, 1/2 kilo
                      and Kilograms. Dry mushrooms are also packaged in any
                      quantities needed.
                    </p>
                    <p className="margin-top-10">
                      All our mushrooms are grown at our mushroom growing and
                      packing facilities in Katabi Entebbe, Uganda. They are
                      GMO-free, organic and fresh for a healthy living and
                      nutrition. In embracing value addition, mushroom world
                      makes mushroom snacks and ready to eat mushroom soup.
                    </p>
                    <p className="margin-top-10">
                      Mushroom World Uganda has been able to partner with other
                      mushroom farmers in the community to expand the supply to
                      the Mushroom market in Entebbe. This market ranges from
                      hotels, supermarkets, catering facilities, restaurants,
                      farmers’ markets and individual homes.{" "}
                    </p>
                    <p className="margin-top-10">
                      Mushroom World also offers training services and
                      accommodation services to students from upcountry.
                    </p>
                  </div>
                  <div className="section-cta">
                    <a
                      href="/products"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button main-cta boldTitle"
                    >
                      Make Your Order Today
                    </a>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutBody;
