import React from "react";
import { Helmet } from "react-helmet";
import ProductsBody from "../components/products/ProductsBody";

export default function Products() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.MushroomWorldUg.com/products" />
        <meta
          name="description"
          content="Mushroom World is a family owned mushroom business located in Entebbe that produces fresh mushrooms, mushroom snacks and offers training services to mushroom farmers around Uganda."
        />
        <meta
          property="og:description"
          content="Mushroom World is a family owned mushroom business located in Entebbe that produces fresh mushrooms, mushroom snacks and offers training services to mushroom farmers around Uganda."
        />
        <meta
          property="og:title"
          content="Our Products | Mushroom World Uganda"
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1697093246/mushroom_gvukum.jpg"
        />
        <meta
          name="twitter:title"
          content="Our Products | Mushroom World Uganda"
        />
        <meta
          name="twitter:text:title"
          content="Our Products | Mushroom World Uganda"
        />
        <meta
          name="twitter:description"
          content="Mushroom World is a family owned mushroom business located in Entebbe that produces fresh mushrooms, mushroom snacks and offers training services to mushroom farmers around Uganda."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1697093246/mushroom_gvukum.jpg"
        />
        <title>Our Products | Mushroom World Uganda</title>
      </Helmet>
      <ProductsBody />
    </>
  );
}
