import React from "react";
import { Helmet } from "react-helmet";
import PageHero from "../components/reusables/hero/PageHero";
import AboutBody from "../components/about/AboutBody";

export default function About() {
  return (
    <>
      <Helmet>
        <link rel="conanical" href="https://www.MushroomWorldUG.com/about" />
        <meta
          name="description"
          content="Mushroom World is a family owned mushroom business located in Entebbe that produces fresh mushrooms, mushroom snacks and offers training services to mushroom farmers around Uganda."
        />
        <meta
          property="og:description"
          content="Mushroom World is a family owned mushroom business located in Entebbe that produces fresh mushrooms, mushroom snacks and offers training services to mushroom farmers around Uganda."
        />
        <meta property="og:title" content="Mushroom World Uganda | ABOUT US" />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1697093246/mushroom_gvukum.jpg"
        />
        <meta name="twitter:title" content="Mushroom World Uganda | ABOUT US" />
        <meta
          name="twitter:text:title"
          content="Mushroom World Uganda | ABOUT US"
        />
        <meta
          name="twitter:description"
          content="Mushroom World is a family owned mushroom business located in Entebbe that produces fresh mushrooms, mushroom snacks and offers training services to mushroom farmers around Uganda."
        />
        <meta
          name="twitter:image"
          content="https://res.cloudinary.com/malidad/image/upload/v1697093246/mushroom_gvukum.jpg"
        />
        <title>Mushroom World Uganda | ABOUT US</title>
      </Helmet>
      <PageHero
        classes="mushroom-bg"
        titleClasses="title uppercase dark"
        title="About Mushroom World Uganda"
      />
      <AboutBody />
    </>
  );
}
