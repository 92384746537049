import React from "react";
import { Slide } from "react-reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { settings1 } from "../reusables/carousel";
import right from "../../assets/images/icons/long-arrow-right-white.svg";
import HeroImgSlider from "../reusables/HeroImgSlider";
import mush2 from "../../assets/images/mrooms/mush2.jpg";
import mush3 from "../../assets/images/mrooms/mush3.jpg";

const TrainingHero = () => {
  return (
    <section className="hero cloudy-bg section-spacing-150">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="hero-content-container">
                <div className="hero-text-side">
                  <div className="hero-text">
                    <Slide top>
                      <h1 className="capitalize">
                        Learn how to farm &amp; earn from Mushrooms
                      </h1>
                    </Slide>
                    <Slide left>
                      <p>
                        We not only train but also provide accomodation to those
                        from far.
                      </p>
                    </Slide>
                  </div>
                  <div className="hero-cta with-300">
                    <a
                      href="https://form.jotform.com/mushroomworldug/mushroom-farming-training-enrolment"
                      className="button boldTitle main-cta"
                    >
                      <span>Enroll Now</span>
                      <img src={right} alt="Right Arrow" />
                    </a>
                  </div>
                </div>
                <div className="hero-img-side">
                  <div className="carous">
                    <div className="slider-items-container center-slide-arrows hero-slider-container">
                      <Slider {...settings1}>
                        <HeroImgSlider pic={mush3} cap="Mushrooms Growing" />
                        <HeroImgSlider pic={mush2} cap="Mushrooms Growing" />
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrainingHero;
