import React from "react";
import { Slide } from "react-reveal";
import delivery from "../../assets/images/mrooms/delivery.svg";
import pack from "../../assets/images/mrooms/package.svg";
import guarantee from "../../assets/images/mrooms/guarantee.svg";

const WhyUs = () => {
  return (
    <section className="sections section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide top>
                <div className="section-head center width-600 margin-auto">
                  <h2 className="capitalize dark">Why Mushroom World Uganda</h2>
                  <p className=" margin-top-10 width-600 margin-auto">
                    Mushrooms are us because we understand them on your behalf
                  </p>
                </div>
              </Slide>
              <div className="section-body section-spacing-60">
                <div className="why-items-container">
                  <div className="why-item">
                    <img src={delivery} alt="Delivery" />
                    <h3>Delivery</h3>
                    <p>Countrywide and Only in Entebbe free delivery.</p>
                  </div>
                  <div className="why-item">
                    <img src={guarantee} alt="Delivery" />
                    <h3>Fresh &amp; Certified</h3>
                    <p>GMO-free, fresh for your taste and healthy for your body.</p>
                  </div>
                  <div className="why-item">
                    <img src={pack} alt="Delivery" />
                    <h3>Recyclable Packages</h3>
                    <p>Well packaged for safe handling and ensure a fresh delivery.</p>
                  </div>
                </div>
                <div className="section-cta section-spacing-20 no-padding-bottom">
                  <a
                    href="/products"
                    className="button uppercase boldTitle main-cta"
                  >
                    {" "}
                    See Our Products
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;
