import React from "react";
import { Slide } from "react-reveal";
import about1 from "../../assets/images/mrooms/ms11.jpg";
import about2 from "../../assets/images/mrooms/m1.jpg";
import about3 from "../../assets/images/mrooms/msgrow.jpg";

const About = () => {
  return (
    <section className="about section-spacing-150 no-padding-bottom">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="shared-section flex section-spacing-80 no-padding-top">
                <Slide left>
                  <div className="images-side flex">
                    <img
                      src={about1}
                      className="box-shadow"
                      alt="About Image"
                    />
                    <img
                      src={about2}
                      className="box-shadow"
                      alt="About Image"
                    />
                    <img
                      src={about3}
                      alt="About Image"
                      className="about-big-img box-shadow"
                    />
                  </div>
                </Slide>
                <div className="text-side">
                  <Slide bottom>
                    <div className="text-side-text center">
                      <h3 className="about-text-side-title dark section-spacing-20">
                        We Grow Real Mushrooms &amp; Pack Them!
                      </h3>
                      <p>
                        GMO-free, organic and fresh for a healthy living and
                        nutrition with across the country delivery. All our
                        mushrooms are grown at our mushroom growing and packing
                        facilities in Katabi Entebbe, Uganda.
                      </p>
                    </div>
                    <div className="section-cta">
                      <a
                        href="/products"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="button main-cta boldTitle"
                      >
                        See Our Products
                      </a>
                    </div>
                  </Slide>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
