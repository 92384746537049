import React from "react";
import map from "../../assets/images/icons/map.svg";
import email from "../../assets/images/icons/email.svg";
import phone from "../../assets/images/icons/phone.svg";
import fb from "../../assets/images/icons/facebook.png";
import twi from "../../assets/images/icons/twitter.png";
import wa from "../../assets/images/icons/whatsapp.png";
import { Slide } from "react-reveal";
import PageHero from "../reusables/hero/PageHero";

const MapBody = () => {
  return (
    <>
      <PageHero
        classes="mushroom-bg"
        title="Contact Mushroom World Uganda"
        titleClasses="title uppercase dark"
      />
      <section className="section-spacing-50 no-padding-top">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content whiteBg width-960 box-shadow relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className="padding-16">
                  <div>
                    <div className="address-wrap flex">
                      <Slide left>
                        <div className="map">
                          <h3 className=" uppercase">Visit Us!</h3>
                          <div className="map-container width-100">
                            <iframe
                              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.8149251161344!2d32.477396999999996!3d0.07474729999999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177d853c7df641b1%3A0xf6d35c47645a8f90!2sMushroom%20World%20Uganda!5e0!3m2!1sen!2sus!4v1697183476974!5m2!1sen!2sus"
                              style={{ border: 0 }}
                              allowfullscreen="true"
                              loading="lazy"
                              referrerpolicy="no-referrer-when-downgrade"
                            ></iframe>
                          </div>
                        </div>
                      </Slide>
                      <Slide right>
                        <div className="address-office">
                          <div>
                            <div className="contacts-container">
                              <div className="contacts-item">
                                <div className="contacts-icon">
                                  <img src={map} alt="Map Icon" />
                                </div>
                                <div className="contacts-text">
                                  <h4>Facilities &amp; Office</h4>
                                  <p className=" margin-top-10">
                                    Canan Estate, Katabi, Entebbe
                                  </p>
                                </div>
                              </div>
                              <div className="contacts-item">
                                <div className="contacts-icon">
                                  <img src={phone} alt="Call Icon" />
                                </div>
                                <div className="contacts-text">
                                  <h4>Phone</h4>
                                  <p className=" margin-top-10">
                                    +256 702 344 701
                                  </p>
                                  <p className=" margin-top-10">
                                    +256 785 173 266
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Slide>
                    </div>
                    <div className="address-message flex section-spacing-30 no-padding-bottom">
                      <a href="mushroomworld@gmail.com" className="button">
                        Get in Touch
                      </a>
                      <div className="social-icons flex">
                        <a
                          href="https://x.com/MushroomWorldUG"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={twi} alt="Twitter Icon" />
                        </a>
                        <a
                          href="https://fb.com/MushroomWorldUG"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={fb} alt="Facebook Icon" />
                        </a>
                        <a
                          href="https://wa.me/256785173266"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={wa} alt="WhatsApp Icon" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MapBody;
