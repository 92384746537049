import React from "react";
import { Slide } from "react-reveal";
import PageHero from "../reusables/hero/PageHero";
import crispyfried from "../../assets/images/mw/Crispy-Fried-Oyster-Mushrooms.jpg";
import freshpacked from "../../assets/images/mw/Fresh-200g-packed-Oyster-Mushrooms.jpg";
import freshharv from "../../assets/images/mw/Freshly-harvested-Oyster-Mushrooms.jpg";
import grayoys from "../../assets/images/mw/Gray-Oyster-Mushroom-Flower.jpg";
import msalad from "../../assets/images/mw/ms-salad.jpg";
import mpies from "../../assets/images/mw/Mushroom-Pies-and-Dumplings.jpg";
import msamosa from "../../assets/images/mw/Mushroom-Samosas.jpg";
import oydry from "../../assets/images/mw/Sun-dried-Oyster-Mushrooms-.jpg";
import whiteoys from "../../assets/images/mw/White-Oysters-Mushroom-flower.jpg";
import mgarden from "../../assets/images/mw/Oyster-Mushroom-Gardens2.jpg";
import mspawns from "../../assets/images/mw/Oyster-Mushroom-Spawns.jpg";
import ProductCard from "../reusables/ProductCard";

const ProductsBody = () => {
  return (
    <>
      <PageHero
        classes="mushroom-bg"
        title="OUR PRODUCTS"
        titleClasses="title uppercase dark"
      />
      <section className="section-spacing-50 no-padding-top">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content whiteBg relative margin-auto border-radius-2">
              <div className="section-body minus-margin-top-80">
                <div className="products-container">
                  <ProductCard
                    img={freshpacked}
                    pname="Oyster Mushrooms"
                    pdesc="Fresh, 200g Packed"
                    link="https://form.jotform.com/mushroomworldug/order-form"
                  />
                  <ProductCard
                    img={oydry}
                    pname="Oyster Mushrooms"
                    pdesc="Sun dried, Packed"
                    link="https://form.jotform.com/mushroomworldug/order-form"
                  />
                  <ProductCard
                    img={freshharv}
                    pname="Oyster Mushrooms"
                    pdesc="Freshly Harvested"
                    link="https://form.jotform.com/mushroomworldug/order-form"
                  />
                  <ProductCard
                    img={crispyfried}
                    pname="Oyster Mushrooms"
                    pdesc="Crispy Fried"
                    link="https://form.jotform.com/mushroomworldug/order-form"
                  />
                  <ProductCard
                    img={grayoys}
                    pname="Oyster Mushrooms"
                    pdesc="Gray Oyster Mushroom Flower"
                    link="https://form.jotform.com/mushroomworldug/order-form"
                  />
                  <ProductCard
                    img={msalad}
                    pname="Salads"
                    pdesc="Mushroom Salads"
                    link="https://form.jotform.com/mushroomworldug/order-form"
                  />
                  <ProductCard
                    img={mpies}
                    pname="Pies &amp; Dumpling"
                    pdesc="Mushroom Pies &amp; Dumplings"
                    link="https://form.jotform.com/mushroomworldug/order-form"
                  />
                  <ProductCard
                    img={msamosa}
                    pname="Samosas"
                    pdesc="Mushroom Samosa"
                    link="https://form.jotform.com/mushroomworldug/order-form"
                  />
                  <ProductCard
                    img={whiteoys}
                    pname="Oyster Mushrooms"
                    pdesc="White Oysters Mushroom flower"
                    link="https://form.jotform.com/mushroomworldug/order-form"
                  />
                  <ProductCard
                    img={mgarden}
                    pname="Mushrooms Gardens"
                    pdesc="Oyster Mushroom Gardens"
                    link="https://form.jotform.com/mushroomworldug/order-form"
                  />
                  <ProductCard
                    img={mspawns}
                    pname="Mushrooms Spawns"
                    pdesc="Oyster Mushroom Spawns"
                    link="https://form.jotform.com/mushroomworldug/order-form"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductsBody;
