import React from "react";
import { Slide } from "react-reveal";
import ui1 from "../../assets/images/mrooms/ms11.jpg";

const Benefits = () => {
  return (
    <section className="sections cloudy-bg cream-bg section-spacing-100">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <Slide top>
                <div className="section-head center width-600 margin-auto">
                  <h2 className="capitalize dark">
                    Health Benefits of Mushrooms
                  </h2>
                  <p className=" margin-top-10 width-600 margin-auto">
                    Mushrooms are widely known for their great taste and amazing
                    health benefits according to{" "}
                    <a
                      href="https://www.webmd.com/diet/health-benefits-mushrooms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      WebMD
                    </a>
                  </p>
                </div>
              </Slide>
              <div className="section-body section-spacing-60">
                <div className="how-container">
                  <div className="sideA how-text-side">
                    <ul>
                      <Slide left>
                        <li className="how-step-card">
                          <div className="how-number">1</div>
                          <h4 className=" capitalize">
                            Kicks Up Your Metabolism
                          </h4>
                          <p>
                            Mushrooms contain beta-glucans, a type of dietary
                            fibre that provides feelings of fullness and
                            satiety.
                          </p>
                        </li>
                      </Slide>
                      <Slide bottom>
                        <li className="how-step-card">
                          <div className="how-number">2</div>
                          <h4 className=" capitalize">
                            Increases Your Vitamin D
                          </h4>
                          <p>
                            Mushrooms exposed to ultraviolet light are a good
                            source of vitamin D, an important component for bone
                            and immune health.
                          </p>
                        </li>
                      </Slide>
                      <Slide bottom>
                        <li className="how-step-card">
                          <div className="how-number">3</div>
                          <h4 className=" capitalize">
                            Improves Your Heart Health
                          </h4>
                          <p>
                            Mushrooms are a rich source of potassium, a nutrient
                            known for reducing the negative impact that sodium
                            can have on your body. Potassium also lessens the
                            tension in blood vessels, potentially helping to
                            lower blood pressure.
                          </p>
                        </li>
                      </Slide>
                    </ul>
                  </div>
                  <Slide top>
                    <div className="uiImage ntuna-yellow-bg padding-16 box-shadow">
                      <img src={ui1} alt="Mushroom" />
                    </div>
                  </Slide>
                  <div className="sideB how-text-side">
                    <ul>
                      <Slide right>
                        <li className="how-step-card">
                          <div className="how-number">4</div>
                          <h4 className=" capitalize">
                            Boosts Your Immune System
                          </h4>
                          <p>
                            Mushrooms contain high amounts of selenium, vitamin
                            D, and vitamin B6. Selenium can help prevent cell
                            damage in our bodies, vitamin D helps with cell
                            growth, and vitamin B6 helps our bodies form red
                            blood cells.
                          </p>
                        </li>
                      </Slide>
                      <Slide top>
                        <li className="how-step-card">
                          <div className="how-number">5</div>
                          <h4 className=" capitalize">Good for Your Bladder</h4>
                          <p>
                            Mushrooms can act as a natural diuretic, and that
                            can relieve bloating symptoms and help your bladder
                            detoxify your body.
                          </p>
                        </li>
                      </Slide>
                      <Slide top>
                        <li className="how-step-card">
                          <div className="how-number">6</div>
                          <h4 className=" capitalize">
                            Gives You Antioxidants
                          </h4>
                          <p>
                            It is thought that the antioxidants in mushrooms may
                            increase cells' defense systems, thus improving
                            anti-inflammatory actions and protecting against
                            obesity-related hypertension.
                          </p>
                        </li>
                      </Slide>
                    </ul>
                  </div>
                </div>
                <div className="section-cta section-spacing-20 no-padding-bottom">
                  <a
                    href="/products"
                    className="button uppercase boldTitle main-cta"
                  >
                    {" "}
                    See Our Products
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
