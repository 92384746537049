import React from "react";
import { Slide } from "react-reveal";
import right from "../../assets/images/icons/long-arrow-right-white.svg";

const HomeHero = () => {
  return (
    <section className="hero home-hero section-spacing-150">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="section-content">
              <div className="hero-content-container">
                <div className="hero-text-side">
                  <div className="hero-text">
                    <Slide top>
                      <h1>MUSHROOM WORLD UGANDA</h1>
                    </Slide>
                    <Slide left>
                      <p>
                        A family owned mushroom business that produces fresh
                        mushrooms, mushroom snacks and offers training services
                        to mushroom farmers around Uganda.
                      </p>
                    </Slide>
                  </div>
                  <div className="hero-cta">
                    <a href="/products" className="button boldTitle main-cta">
                      <span>See Our Products</span>
                      <img src={right} alt="Right Arrow" />
                    </a>
                    <a
                      href="https://form.jotform.com/mushroomworldug/mushroom-farming-training-enrolment"
                      className="button boldTitle sub-cta"
                    >
                      <span>Enroll for Training</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeHero;
