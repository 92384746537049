import React from "react";

const Footer = () => {
  return (
    <section className="section-spacing-50 no-padding-bottom mushroom-bg">
      <div className="container">
        <div className="large">
          <div className="section-content-container">
            <div className="footer-items-container flex">
              <div className="footer-item">
                <h3>About</h3>
                <div>
                  <p>
                    Mushroom World Uganda Limited was established in the first
                    Covid lockdown as a means of utilizing the limited space to
                    generate household income with an inspiration from the 2019
                    Money Harvest Expo.{" "}
                  </p>
                  <p className="margin-top-10">
                    We grow mushrooms of all types and deliver them packaged,
                    fresh and dried. We are located in Canan Estate, Katabi,
                    Entebbe, we do country wide delivery and Mushroom Farming
                    Training.
                  </p>
                </div>
              </div>
              <div className="footer-item footer-item-links">
                <h3>Quick Links</h3>
                <ul>
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <a href="/about">About Us</a>
                  </li>
                  <li>
                    <a href="map">Contact Us</a>
                  </li>

                  <li>
                    <a href="/products">Our Products</a>
                  </li>
                  <li>
                    <a href="/training">Mushroom Farming Training</a>
                  </li>
                </ul>
              </div>
              <div className="footer-item footer-item-links">
                <h3>Social Media</h3>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://x.com/MushroomWorldUG"
                    >
                      Twitter
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://fb.com/MushroomWorldUG"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://instagram.com/MushroomWorldUG"
                    >
                      Instagram
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://wa.me/256785173266"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      WhatsApp MTN
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://wa.me/256702344701"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      WhatsApp Airtel
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="copyright margin-top-20 center gray">
              <p>
                &copy; 2023 Mushroom World Uganda Limited, All rights reserved
              </p>
            </div>
            <div className="designer section-spacing-10">
              <div className="design-inner center uppercase gray">
                <p>
                  Website designed by{" "}
                  <a href="https://ntuna.com" className="link">
                    NTUNA.COM
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
